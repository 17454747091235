import { MEDICALCONDITIONS } from '../defaultContent'
import { Page } from '../types'

const health: Page[] = [
    {
        pageType: 'information',
        title: 'Before you begin',
        name: 'health-before-you-begin',
        description:
            'Tell us if you are living with any health conditions in this section, so we can understand how you would manage them. \n  If we need more information from your doctor, we will ask your consent before we contact them. \n Most health conditions will not stop you from getting security clearance and are reviewed on a case by case basis. \n You only need to tell us about health conditions we ask about in this section.'
    },
    {
        pageType: 'question',
        title: 'Diagnosis and treatment',
        name: 'diagnosis-and-treatment',
        questions: [
            {
                name: 'diagnosis-and-treatment-conditions',
                questionType: 'radio',
                title: "Select a condition you've received treatment for or been diagnosed with:",
                hint: 'Include a previous or current diagnosis.  Only include  a condition where you have got help from a medical professional.',
                options: MEDICALCONDITIONS,
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must select a condition or "None of the above"'
                }
            }
        ]
    },
    {
        disabled: [
            {
                field: 'diagnosis-and-treatment-conditions',
                condition: 'equals',
                value: 'None of the above'
            }
        ],
        pageType: 'question',
        title: 'Diagnosis and treatment for [diagnosis-and-treatment-conditions]',
        name: 'diagnosis-and-treatment-for-condition',
        questions: [
            {
                name: 'diagnosis-date',
                questionType: 'shortDate',
                title: 'When did you first seek medical help for [diagnosis-and-treatment-conditions]?',
                hint: 'Approximate date. For example 06 2018',
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Enter the date you first sought medical help'
                }
            },
            {
                name: 'who-diagnosed',
                questionType: 'textInput',
                title: 'Who made the diagnosis?',
                hint: 'For example the name and job title (e.g. GP Specialist, clinical psychologist) of the medical professional (if known)',
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must enter who made the diagnosis'
                }
            },
            {
                name: 'what-diagnosis',
                questionType: 'textarea',
                title: 'What diagnosis were you given?',
                hint: 'Provide any further details you can',
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must enter what diagnosis you were given'
                }
            },
            {
                name: 'clinic-or-outpatient-treatment',
                questionType: 'radio',
                title: 'Have you received any hospital (including A&E) or clinic (outpatient treatment) for [diagnosis-and-treatment-conditions]?',
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Select whether you received any hospital or clinic treatment'
                },
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                name: 'treatment-further-details',
                                questionType: 'textarea',
                                title: 'Provide details of any treatment or therapy with approximate dates',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details of treatment or therapy'
                                }
                            }
                        ]
                    },
                    'No'
                ]
            },
            {
                name: 'hospital-or-inpatient-treatment',
                questionType: 'radio',
                title: 'Have you ever stayed in hospital as an in-patient to be treated?',
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Select whether you have stayed in a hospital as an in-patient'
                },
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                name: 'hospital-or-inpatient-treatment-further-details',
                                questionType: 'textarea',
                                title: 'Please provide details and dates',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details and dates of your hospital stay'
                                }
                            }
                        ]
                    },
                    'No'
                ]
            },
            {
                name: 'mental-health-act',
                questionType: 'radio',
                title: 'Have you ever been detained under the Mental Health Act?',
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Select whether you have ever been detained under the Mental Health Act'
                },
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                name: 'mental-health-act-further-details',
                                questionType: 'textarea',
                                title: 'Please provide details and dates',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details and dates'
                                }
                            }
                        ]
                    },
                    'No'
                ]
            },
            {
                name: 'inform-dvla',
                questionType: 'radio',
                title: 'Have you or your GP ever had to inform the DVLA of your condition or have you ever been advised by the DVLA against driving?',
                rules: {
                    required: true
                },
                errorMessages: {
                    required:
                        'Select whether the DVLA has been informed of your condition or advised you against driving'
                },
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                name: 'inform-dvla-further-details',
                                questionType: 'textarea',
                                title: 'Please provide details and dates',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details and dates'
                                }
                            }
                        ]
                    },
                    'No'
                ]
            }
        ]
    },
    {
        disabled: [
            {
                field: 'diagnosis-and-treatment-conditions',
                condition: 'equals',
                value: 'None of the above'
            }
        ],
        pageType: 'question',
        title: 'Management of condition',
        name: 'management-of-condition',
        questions: [
            {
                name: 'medication',
                questionType: 'radio',
                title: 'Do you currently take any medication to manage [diagnosis-and-treatment-conditions]?',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                name: 'medication-details',
                                questionType: 'textarea',
                                title: 'Provide the name of medication, how often you take it and the dosage',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must enter the name, how often you take it and the dosage'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Select whether you take any medication to manage this condition'
                }
            },
            {
                name: 'condition-compared-to-twelve-months-ago',
                questionType: 'textarea',
                title: 'In your opinion, how is your condition now compared to 12 months ago?',
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must provide details on your condition compared to 12 months ago'
                }
            }
        ]
    },
    {
        disabled: [
            {
                field: 'diagnosis-and-treatment-conditions',
                condition: 'equals',
                value: 'None of the above'
            }
        ],
        pageType: 'question',
        title: 'Impact on work',
        name: 'impact-on-work',
        questions: [
            {
                name: 'medical-absence',
                questionType: 'radio',
                title: 'Have you ever been off work because of [diagnosis-and-treatment-conditions] in the last 2 years?',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                name: 'medical-absence-dates',
                                questionType: 'textarea',
                                title: 'Provide approximate dates (month and year or year)/number of days for each absence',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details for each absence'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Select whether you have been off work because of your condition in the past 2 years'
                }
            },
            {
                name: 'line-manager-awareness',
                questionType: 'radio',
                title: 'Is your line manager or anyone in your reporting chain aware of your [diagnosis-and-treatment-conditions]?',
                hint: 'We will always ask for your consent first if we need to contact your workplace or line manager',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                name: 'line-manager-awareness-details',
                                questionType: 'textarea',
                                title: 'Please provide details of who at work knows of your condition',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details of who at work knows of your condition'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Select whether your line manager or anyone in your reporting chain is aware'
                }
            },
            {
                name: 'occupational-health-assessment',
                questionType: 'radio',
                title: 'Have you undergone an occupational health assessment at any time?',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                name: 'occupational-health-assessment-details',
                                questionType: 'textarea',
                                title: 'Provide details of any adjustments which were suggested/made and the approximate dates these took place',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details of adjustments'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Select whether you have undergone a health assessment'
                }
            },
            {
                name: 'condition-well-managed',
                questionType: 'radio',
                title: 'In your opinion is your condition well managed, i.e. your day to day activities are not adversely affected?',
                options: ['Yes', 'No'],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Select whether your condition is well managed'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Addictions',
        name: 'addictions',
        questions: [
            {
                name: 'addictions-question',
                questionType: 'radio',
                title: 'Have you ever been addicted to something which has affected your health or lifestyle?',
                hint: 'For example a dependency on alcohol, drugs, gambling or pornography',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                name: 'addictions-question-details',
                                questionType: 'textarea',
                                title: 'Provide details of the addiction, including if you sought help, when this was, who gave you help and how you manage the addiction',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details of the addiction'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required:
                        'Select whether you have been addicted to something which has affected your health or lifestyle'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Declaration',
        name: 'health-declaration',
        description:
            '<a href="../../pdf/Merged.pdf" download="Medical consent form" className="govuk-link">Download the medical consent form</a>',
        questions: [
            {
                questionType: 'fileUpload',
                name: 'medical-consent-upload',
                title: '',
                label: 'Upload your medical consent form'
            },
            {
                name: 'health-declaration-checkbox',
                questionType: 'checkbox',
                title: 'Health declaration',
                options: [
                    'I, [first-name] [last-name], confirm that the information about my health is true and accurate to the best of my knowledge and I have signed and submitted the Medical Consent Form.'
                ],
                rules: {
                    required: true
                }
            }
        ]
    }
]

export default health
