import { ChangeEvent, useRef, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from '../ErrorMessage'
import Checkbox from '../Checkbox'
import { isObject } from '../../../utils/array-utils'
import TextInputField from '../TextInputField'
import { validateField } from '../../../utils/form-utils'
import Textarea from '../Textarea'
import { DateInputField } from '../DateInputField/DateInputField'
import { ShortDateInputField } from '../DateInputField/ShortDateInputField'
import SelectInputField from '../SelectInputField'
import { Question, SelectOptionDefault, SelectOptionGroup } from '../../../config/types'
import ConditionalRadioField from '../RadioGroup/ConditionalRadioField'
import ConditionalCheckboxField from './ConditionalCheckboxField'
import { DefaultCheckbox } from './DefaultCheckbox'
import { CheckboxGroupProps, CheckboxItemProps, CheckboxSubGroupProps, ConditionalCheckboxProps } from './Props'
import AutocompleteWrapper from '../Autocomplete/AutocompleteWrapper'
import Details from '../Details'
import DCFMarkdown from '../../DCFMarkdown/DCFMarkdown'
import handleDynamicText from '../../../hooks/handleDynamicText'

const ConditionalCheckbox = ({
    id,
    anchorId,
    index,
    item,
    currentValue,
    name,
    disabled,
    handleOnChange,
    displayValue
}: ConditionalCheckboxProps) => {
    const value = item.option
    const fieldId = !!anchorId && index === 0 ? anchorId : `${id}${value}${anchorId}`
    const questions = (item.questions as Question[]) ?? []
    const showConditional = !!questions.length && currentValue.includes(value)
    const conditionalFieldId = `${fieldId}_conditional`

    const {
        unregister,
        formState: { errors }
    } = useFormContext()

    const previousValue = useRef<string[]>()

    // Need to check if we were the previously selected option
    // if so we need to unregister all the sub-questions that we have.
    useEffect(() => {
        if (previousValue.current?.includes(value)) {
            const questionNames: string[] = []
            questions.reduce((questionNames, question) => {
                questionNames.push(question.name)
                return questionNames
            }, questionNames)
            unregister(questionNames)
        }
        previousValue.current = currentValue
    }, [currentValue])

    return (
        <>
            <div className='govuk-checkboxes__item' key={fieldId}>
                <Checkbox
                    id={fieldId}
                    name={name}
                    disabled={disabled ?? false}
                    value={value}
                    checked={currentValue.includes(value)}
                    onChange={e => handleOnChange(e, value)}
                    aria-controls={conditionalFieldId}
                    aria-expanded={showConditional}
                />
                <label className='govuk-label govuk-checkboxes__label' htmlFor={fieldId}>
                    {displayValue || value}
                </label>
            </div>
            {showConditional && (
                <div
                    className={
                        showConditional
                            ? 'govuk-checkboxes__conditional'
                            : 'govuk-checkboxes__conditional govuk-checkboxes__conditional--hidden'
                    }
                    id={conditionalFieldId}
                    hidden={!showConditional}
                >
                    {questions.map(question => {
                        const rules = question?.rules || {}

                        return (
                            <div
                                className={`govuk-form-group ${
                                    errors && errors[question.name] && 'govuk-form-group--error'
                                }`}
                                key={question.name}
                            >
                                <label className='govuk-label' htmlFor={`${id}${question.name}`}>
                                    {rules?.required === true ? question.title : `${question.title} (optional)`}
                                </label>
                                {question.hint && <DCFMarkdown content={question.hint} type='hint-text' />}
                                {question.questionType === 'textInput' && (
                                    <TextInputField
                                        name={question.name}
                                        disabled={disabled ?? false}
                                        id={`${id}${question.name}`}
                                        rules={rules}
                                        title={question.title}
                                        errorMessages={question.errorMessages}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'textarea' && (
                                    <Textarea
                                        name={question.name}
                                        disabled={disabled ?? false}
                                        numeric={!!question.rules?.number || undefined}
                                        id={`${id}${question.name}`}
                                        rules={rules}
                                        title={question.title}
                                        errorMessages={question.errorMessages}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'radio' && (
                                    <ConditionalRadioField
                                        question={question}
                                        disabled={disabled ?? false}
                                        id={`${id}${question.name}`}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'date' && (
                                    <DateInputField
                                        question={question}
                                        anchorId={`${id}${question.name}`}
                                        disabled={disabled}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'shortDate' && (
                                    <ShortDateInputField
                                        question={question}
                                        anchorId={`${id}${question.name}`}
                                        disabled={disabled}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'checkbox' && (
                                    <ConditionalCheckboxField
                                        question={question}
                                        disabled={disabled ?? false}
                                        id={`${id}${question.name}`}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'select' && (
                                    <SelectInputField
                                        name={question.name}
                                        id={`${id}${question.name}`}
                                        hintOption={question.hintOption}
                                        options={question.options || []}
                                        disabled={disabled}
                                        rules={rules}
                                        title={question.title}
                                        errorMessages={question.errorMessages}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'autoComplete' && (
                                    <AutocompleteWrapper
                                        id={id}
                                        items={question.items || []}
                                        name={question.name}
                                        title={question.title}
                                        rules={rules}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </>
    )
}

const CheckboxItem = ({
    id,
    anchorId,
    index,
    item,
    currentValue,
    name,
    disabled,
    handleOnChange,
    displayValue
}: CheckboxItemProps) => {
    const isConditionalCheckbox = isObject(item) && 'questions' in item
    return (
        <>
            {isConditionalCheckbox && (
                <ConditionalCheckbox
                    id={id}
                    anchorId={anchorId}
                    name={name}
                    disabled={disabled}
                    index={index}
                    item={item as SelectOptionDefault}
                    currentValue={currentValue}
                    handleOnChange={handleOnChange}
                    displayValue={displayValue}
                />
            )}
            {!isConditionalCheckbox && (
                <DefaultCheckbox
                    id={id}
                    name={name}
                    disabled={disabled}
                    anchorId={anchorId}
                    index={index}
                    item={item as string}
                    currentValue={currentValue}
                    handleOnChange={handleOnChange}
                    displayValue={displayValue}
                />
            )}
        </>
    )
}

const CheckboxSubGroup = ({
    id,
    anchorId,
    currentValue,
    option,
    name,
    disabled,
    handleOnChange
}: CheckboxSubGroupProps) => (
    <div className='govuk-radios fieldset-container' data-module='govuk-radios' key={option.label}>
        <legend className='govuk-fieldset__legend govuk-fieldset__legend--s'>
            <h3 className='govuk-fieldset__heading'>{option.label}</h3>
        </legend>
        {option.options.map((option, index) => (
            <CheckboxItem
                id={id}
                name={name}
                disabled={disabled}
                key={option}
                anchorId={anchorId}
                index={index}
                item={option as string | SelectOptionDefault}
                currentValue={currentValue}
                handleOnChange={handleOnChange}
            />
        ))}
    </div>
)

export default function CheckboxGroup({
    anchorId,
    question,
    disabled,
    id,
    detailsHeading,
    detailsContent,
    formAnswers
}: CheckboxGroupProps) {
    const { options, name, title, rules, errorMessages } = question
    const { register, getFieldState, getValues, setValue } = useFormContext()
    const [currentValue, setCurrentValue] = useState(getValues(name) ?? [])

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>, option: string) => {
        const newValues = e.target.checked
            ? [...currentValue, option]
            : currentValue.filter((v: string) => v !== option)

        setCurrentValue(newValues)
        setValue(name, newValues)
    }

    useEffect(() => {
        if (getValues(name) === undefined) {
            setValue(name, [])
        }
    }, [])

    const hooksFormRegister = register(name, {
        validate: (value: string) => validateField(title, rules || {}, value, errorMessages)
    })
    const { error } = getFieldState(name)

    // If the options contains a SubGroup they must all be subgroups
    const optionIsSubGroup: boolean = (options && typeof options[0] === 'object' && 'label' in options[0]) || false

    return (
        <>
            {error && <ErrorMessage error={error} />}
            {detailsHeading && <Details heading={detailsHeading} content={detailsContent} />}

            <div className='govuk-checkboxes' data-module='govuk-checkboxes'>
                {options &&
                    options.map((option, i) => (
                        <>
                            {optionIsSubGroup && (
                                <CheckboxSubGroup
                                    {...hooksFormRegister}
                                    id={id}
                                    name={name}
                                    disabled={disabled}
                                    anchorId={anchorId}
                                    option={option as SelectOptionGroup}
                                    currentValue={currentValue}
                                    handleOnChange={handleOnChange}
                                />
                            )}
                            {!optionIsSubGroup && (
                                <CheckboxItem
                                    {...hooksFormRegister}
                                    id={id}
                                    name={name}
                                    disabled={disabled}
                                    anchorId={anchorId}
                                    index={i}
                                    item={option as string | SelectOptionDefault}
                                    displayValue={handleDynamicText(option as string, formAnswers)}
                                    currentValue={currentValue}
                                    handleOnChange={handleOnChange}
                                />
                            )}
                        </>
                    ))}
            </div>
        </>
    )
}
